import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Alex Munteanu" subtitle="PSI Board Chairperson" mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAAFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEGhn6EORdTKWhqo8eT/8QAHhAAAgICAgMAAAAAAAAAAAAAAgMAAQQREhMhIjP/2gAIAQEAAQUCUHax6BpcTfEy+fiAO35BcMfsuoF+2YW3z//EABcRAAMBAAAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8BAixf/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQIBAT8BIf/EAB4QAAEDBAMAAAAAAAAAAAAAAAEAAhEDECFBMVJy/9oACAEBAAY/AgOES1xxq0FOgLK8pjNuybVFHURb/8QAHhAAAgICAgMAAAAAAAAAAAAAAAERITFBUYGRoeH/2gAIAQEAAT8hZKRy2NLITLZjRwA6sk42lNCWixSFhWfRcnCKBIRJaLbj38GM1QngeT//2gAMAwEAAgADAAAAELzbwP/EABcRAQEBAQAAAAAAAAAAAAAAAAEQQVH/2gAIAQMBAT8QFTHXZ//EABoRAAICAwAAAAAAAAAAAAAAAAABECERQVH/2gAIAQIBAT8QbvEaFcP/xAAgEAEBAAIBAwUAAAAAAAAAAAABEQAhMVGxwUFhgZGh/9oACAEBAAE/EGuQNG6O7i/SgSdUnrgpkPvc0qRQqFeMa4VUvRWYk4BreQDuO7ga/vfDmHdApz3T6wZBnUuM6J+KKfDPEYSvOJcf/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg",
              "srcSet": ["/static/656bd434711c4ab133ea1f7955b8e1d6/f93b5/01.jpg 300w", "/static/656bd434711c4ab133ea1f7955b8e1d6/b4294/01.jpg 600w", "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`We have had a very successful year despite the unprecedented challenges brought by Covid-19.`}</p>
    </blockquote>
    <p>{`I’d like to express my greatest gratitude and appreciation to Director Rachel Caldwell for her inspirational leadership, to the Leadership Team, all faculty and staff, my fellow Board members, and the PTA for their commitment, dedication and flexibility. And my special thanks go to our greatest and bravest community members, our students and parents, who adjusted to new realities with courage, self-dedication, grace and humour.`}</p>
    <p>{`In the 2019 / 20 school year, we stayed focused on strategic priorities. PSI is in a strong financial position, thanks to many years of good management and sound and responsible corporate governance that provide a solid base for investment. After successfully finishing ‘Phase II’ of our modern campus reconstruction, the Board, its committees, and the School’s leadership have been seriously considering a new ‘Phase III’ that will provide even more students with world class international facilities. We have made significant progress in its launch and implementation and expect to continue our planning in 2020 / 21.`}</p>
    <p>{`After finalising our Community’s Core Values, the 2019 / 20 school year was also when we decided to focus on establishing Leading School Benchmarks (LSBs) and reviewing PSI’s strategy based on those standards. It was also a school year with a major focus on academics, and the first year for Cindy Beals as Director of Learning – a new position that was created last school year. We have launched several important initiatives to develop closer ties with leading colleges in Europe and the US as well as a better interaction with the PSI alumni community worldwide – with successful alumni gatherings in Kyiv, London and New York organised and new plans in place for the next school year. We also have a new banker – Citibank US - and are happy about this new professional relationship.`}</p>
    <p>{`PSI’s IT platform keeps improving, which was crucial for Distance Learning during the quarantine and school closure. We face a new reality:  With all of our strong desire to re-open our school and get back to normal educational activities, we may be forced to return to Distance Learning at any point in the future. It’s a ‘black swan' that nobody could have expected or predicted, but we must be ready to adjust and react swiftly! In response to these exceptional times, we have created a Crisis Management Sub-Group, including Leadership Team and Board of Governors’ membership. This group will meet throughout the summer on a regular basis and will continue planning for the new school year. The entire Board of Governors will also continue to convene monthly throughout the summer.`}</p>
    <p>{`The main goal of the PSI community is to focus on educational activities. Our students stay in the centre of all we do, and their safety, well-being and valuable future are our main priorities. I also believe that this unexpectedly trying period – staying at home, working online – leads us all to a greater self-discipline and creates new challenges and opportunities.`}</p>
    <p>{`We’ll always keep learning, adjusting, improving, excelling and leading. That's what PSI is all about!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      